<template>
  <div style="overflow:initial;">
    <v-navigation-drawer v-model="drawer" floating :color="color.theme" :dark="color.darkTheme" app height="100vh">
      <div class="text-center mt-2 pointer" @click="reloadtomydrive()">
        <img src="@/assets/logo_onebox-horizontal-white.png" width="38%" height="38%" />
      </div>
      <v-list dense nav>
        <v-divider></v-divider>
        <v-menu transition="slide-y-transition" bottom close-on-click offset-y>
          <template v-slot:activator="{ on }">
            <div class="text-center pa-2">
              <v-btn light v-on="on" depressed block class="elevation-0" :disabled="permission_denied_account">
                <v-icon class="mr-2" :color="color.theme">mdi-cloud-upload</v-icon>
                {{ " " + $t("new") }}
              </v-btn>
            </div>
          </template>
          <v-list nav>
            <v-list-item @click.stop="openfile()">
              <v-list-item-avatar>
                <v-icon size="30" color="blue-grey darken-4">mdi-file-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-title style="color: #263238">{{ $t("uploadfile.name") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="opencreatefolder()">
              <v-list-item-avatar>
                <v-icon size="30" color="blue-grey darken-4">mdi-folder-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-title style="color: #263238">{{ $t("mainRClick.uploadfolder") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="openfolder()">
              <v-list-item-avatar>
                <v-icon size="30" color="blue-grey darken-4">mdi-folder-plus</v-icon>
              </v-list-item-avatar>
              <v-list-item-title style="color: #263238">{{ $t("uploadfolder.name") }}</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click.stop="fn_shortcut()">
              <v-list-item-avatar>
                <v-icon size="30" color="blue-grey darken-4">mdi-file-link</v-icon>
              </v-list-item-avatar>
              <v-list-item-title style="color: #263238">{{ $t("shortcut_header") }}</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-divider></v-divider>
        <div class="mt-2 mb-2">
          <v-autocomplete
            @change="changestorage_sidebar(choosetypeuser)"
            :items="typeuser"
            :item-text="$t('default') === 'en' ? 'nameen' : 'nameth'"
            :item-color="color.theme"
            outlined
            v-model="choosetypeuser"
            return-object
            dense
            hide-details
            prepend-inner-icon="work"
          >
            <template :color="color.theme" v-slot:item="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 14px; ">{{ item.nameen }}</span>
              <span v-else style="font-size: 14px;">{{ item.nameth }}</span>
            </template>
            <template :color="color.theme" v-slot:selection="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 13px; ">{{ item.nameen }}</span>
              <span v-else style="font-size: 13px;">{{ item.nameth }}</span>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>
        <!-- <v-list-item-group v-model="activemenu" color="white"> -->
        <template v-for="item in listmenu()">
          <v-row :color="color.theme" v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            color="white"
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            prepend-icon
            :append-icon="item.model ? item.icon : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link @click="tolink(child.link, child.text)">
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t(child.text) }}</v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action v-if="child.icon">
                  <v-icon></v-icon>
              </v-list-item-action>-->
            </v-list-item>
          </v-list-group>
          <!-- <div v-else :key="item.text"> -->
          <v-list-item v-else @click="tolink_parent(item.link, item.text)" :disabled="item.disable" :key="item.text">
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!-- </div> -->
        </template>
        <!-- </v-list-item-group> -->
      </v-list>
      <div v-if="dataBusinessProfile.length > 0 && dataAccountActive.type === 'Business'">
        <v-divider></v-divider>
        <!-- {{permission_inbox_outbox}} -->
        <v-list dense nav>
          <v-list-group append-icon="keyboard_arrow_down" color="white">
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t("forbusiness") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <div v-if="status_permission_inbox === 'AD' || status_permission_inbox === 'AL' || status_permission_inbox === ''">
              <v-list-item link :to="item.link" :disabled="item.disable" :key="i" v-for="(item, i) in forbusiness_inbox">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div v-if="status_permission_outbox === 'AD' || status_permission_outbox === 'AL' || status_permission_outbox === ''">
              <v-list-item link :to="item.link" :disabled="item.disable" :key="i" v-for="(item, i) in forbusiness_outbox">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item :to="item.link" :disabled="item.disable" :key="i" v-for="(item, i) in library">
            <v-list-item-content>
              <v-list-item-title style="font-size: 13px;">{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <!-- cctv -->
      <div v-if="dataAccountActive.type === 'Citizen' && dataAccountCCTV.status === true">
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item link :to="item.link" :disabled="item.disable" :key="i" v-for="(item, i) in cctv">
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </div>
      <!-- เมนูล่างซ้าย -->
      <template v-slot:append>
        <div v-if="dataAccountActive.type === 'Citizen'">
          <v-list nav dense>
            <v-list-item>
              <v-list-item-action class="text-center">
                <v-icon>storage</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("toolbar.storage") }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    :color="percentagestoage_setcolor"
                    rounded
                    :value="percentageStorage"
                  ></v-progress-linear>
                  <br />
                  {{ storage_usage }} in {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="dataAccountActive.type === 'Business'">
          <!-- <v-list nav dense>
            <v-list-item>
              <v-list-item-action class="text-center">
                <v-icon>storage</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("toolbar.storage") }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    :color="percentagestoagecitizeninbiz_setcolor"
                    rounded
                    :value="percentageStoragecitizeninbiz"
                  ></v-progress-linear>
                  <br />
                  {{ storage_usage }} in {{ storage_max_personal_biz }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
       <v-list nav dense>
            <v-list-item>
              <v-list-item-action class="text-center">
                <v-icon>storage</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("toolbar.storage") }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    :color="percentagestoagebiz_setcolor_right"
                    rounded
                    :value="percentageStoragebusiness"
                  ></v-progress-linear>
                  <br />
                  {{ storage_usage_biz }} in {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div>
          <v-flex class="text-center ma-1">
            <v-btn outlined small block @click="opendialogcontact = true">{{ $t("buystorage") }}</v-btn>
          </v-flex>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      class="elevation-0"
      :scroll-threshold="1"
      app
      color="white"
      style="border-bottom-color: #0000001F !important; border-style: solid; border-width: 0 0 thin 0;"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" :color="color.theme"></v-app-bar-nav-icon>
      <!-- search -->
      <v-row class="ma-5" v-if="quicksearch === true">
        <v-col
          cols="12"
          sm="6"
          lg="10"
          v-if="$router.app['_route']['name'] === 'myfiles' || $router.app['_route']['name'] === 'directory'"
        >
          <v-menu
            v-model="advancesearch"
            offset-y
            transition="slide-y-transition"
            :close-on-click="false"
            :close-on-content-click="false"
            max-height="600"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-if="imageHeight >= 400"
                v-model="search"
                :label="$t('quicksearch')"
                append-icon="search"
                dense
                single-line
                solo-inverted
                flat
                clearable
                hide-details
                :color="color.theme"
                @click:clear="$emit('loadfile')"
                v-on="on"
              ></v-text-field>
              <v-btn v-on="on" icon :color="color.theme" v-else>
                <v-icon>search</v-icon>
              </v-btn>
            </template>

            <v-card outlined>
              <v-card-title>
                <v-subheader class="ma-0 pa-0">
                  <v-radio-group v-model="selectSearch">
                    <v-radio value="advance_search"></v-radio>
                  </v-radio-group>
                  {{ $t("toolbar.searching.name") }}
                </v-subheader>
                <v-spacer />
                <v-btn
                  icon
                  :color="color.theme"
                  @click="(advancesearch = false), $emit('loadfile'), (selectSearch = 'advance_search')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <!-- <v-card-text>
                <v-radio-group v-model="typeSearch" class="ma-0 pa-0">
                  <v-radio :label="$t('toolbar.searching.keywordsearch')" value="keywordsearch"></v-radio>
                  <v-radio
                    :label="$t('toolbar.searching.fulltextsearch')"
                    value="fulltextsearch"
                    :disabled="!ismydrive"
                  ></v-radio>
                </v-radio-group>
                <div v-if="typeSearch == 'fulltextsearch'">
                  <v-divider></v-divider>
                  <v-subheader class="ma-0 pa-0">{{$t('toolbar.searching.optional_fulltextearch')}}</v-subheader>
                  <v-radio-group v-model="type_Fulltextsearch" class="ma-0 pa-0">
                    <v-radio
                      :label="$t('toolbar.searching.optional_name_fulltextearch')"
                      value="file_name"
                    ></v-radio>
                    <v-radio
                      :label="$t('toolbar.searching.optional_content_fulltextearch')"
                      value="content_text"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>-->
              <v-card-text>
                <v-layout row wrap justify-center fill-height class="pb-4">
                  <v-flex lg10>
                    <!-- Checkbox Option Search -->
                    <span class="pa-0" style="font-size:12px">{{$t('toolbar.searching.searchby')}}</span>
                    <v-row class="" dense no-gutters >
                      <v-col cols="4">
                        <!-- Column 1 -->
                        <v-checkbox
                          hide-details
                          value="foldername"
                          v-model="optionSearch"
                          :label="$t('toolbar.searching.optional_folder_name')"
                          :color="color.theme"
                          :disabled="selectSearch == 'aspect_search'"
                          
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          value="hashtag"
                          v-model="optionSearch"
                          :label="$t('toolbar.searching.optional_hashtag_fulltextearch')"
                          :color="color.theme"
                          :disabled="selectSearch == 'aspect_search'"
                          
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <!-- Column 2 -->
                        <v-checkbox
                          hide-details
                          value="filename"
                          v-model="optionSearch"
                          :label="$t('toolbar.searching.optional_name_fulltextearch')"
                          :color="color.theme"
                          :disabled="selectSearch == 'aspect_search'"
                          
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <!-- Column 3 -->
                        <v-checkbox
                          hide-details
                          value="file_content"
                          v-model="optionSearch"
                          :label="$t('toolbar.searching.optional_content_fulltextearch')"
                          :color="color.theme"
                          :disabled="selectSearch == 'aspect_search'"
                         
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <!-- <v-radio-group
                      v-model="type_Fulltextsearch"
                      class="ma-0 pa-0"
                      row
                      :disabled="selectSearch == 'aspect_search'"
                    >
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_folder_name')"
                        value="folder"
                      ></v-radio>
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_name_fulltextearch')"
                        value="file_name"
                      ></v-radio>
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_content_fulltextearch')"
                        value="content_text"
                      ></v-radio>
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_hashtag_fulltextearch')"
                        value="hashtag"
                      ></v-radio>
                    </v-radio-group> -->
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center fill-height >
                  <v-flex lg10 class="pa-1">
                    <v-text-field
                      v-model="search"
                      outlined
                      :label="$t('toolbar.searching.detail')"
                      :color="color.theme"
                      dense
                      prepend-icon="image_search"
                      :disabled="selectSearch == 'aspect_search'"
                      @keyup.enter="calladvancesearchdata"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center fill-height >
                  <v-flex lg5 class="pa-1">
                    <v-dialog
                      persistent
                      v-model="menufromdate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromdate"
                          :color="color.theme"
                          :label="$t('toolbar.searching.searchfromdate')"
                          :disabled="selectSearch == 'aspect_search' || type_Fulltextsearch == 'hashtag'"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          clearable
                          @keyup.enter="calladvancesearchdata"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fromdate" @input="menufromdate = false" :color="color.theme" @keyup.enter="calladvancesearchdata" :max="date_now">
                      <v-spacer></v-spacer>
                      <v-btn
                        dark
                        color="error"
                        @click="menufromdate =false"
                      >
                        ปิด
                      </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex lg5 class="pa-1">
                    <v-dialog
                      persistent
                      v-model="menutodate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          :color="color.theme"
                          :disabled="selectSearch == 'aspect_search' || type_Fulltextsearch == 'hashtag'"
                          :label="$t('toolbar.searching.searchtodate')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          clearable
                          @keyup.enter="calladvancesearchdata"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="todate" @input="menutodate = false" :color="color.theme" @keyup.enter="calladvancesearchdata" :max="date_now">
                        <v-spacer></v-spacer>
                          <v-btn
                            dark
                            color="error"
                            @click="menutodate =false"
                          >
                            ปิด
                          </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-divider></v-divider>
              <!-- Aspect Search -->
              <v-card-title v-if="aspect_data[0].aspect == 'True'">
                <v-subheader class="ma-0 pa-0">
                  <v-radio-group v-model="selectSearch">
                    <v-radio value="aspect_search"></v-radio>
                  </v-radio-group>
                  {{ $t("toolbar.searching.aspect_name") }}
                </v-subheader>
                <v-spacer />
              </v-card-title>
              <v-card-text v-if="aspect_data[0].aspect == 'True'" >
                <div v-for="(item, index) in aspect_data[0].aspect_key" :key="index" >
                  <v-layout row wrap justify-center fill-height v-if="item.key_type == 'varchar'">
                    <v-flex lg10 class="pa-1">
                      <v-text-field
                        outlined
                        :label="item.key_name"
                        :color="color.theme"
                        dense
                        @input="(aspect_search) => updateAspectData(index, aspect_search)"
                        prepend-icon="image_search"
                        :disabled="selectSearch == 'advance_search'"
                        @keyup.enter="aspectbutton"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center fill-height v-if="item.key_type == 'dropdown'"  >
                    <v-flex lg10>
                      <v-select
                        :items="item.data"
                        :label="item.key_name"
                        :value="item.data"
                        @input="(aspect_value) => updateAspectData(index, aspect_value)"
                        outlined
                        dense
                        prepend-icon="image_search"
                        :disabled="selectSearch == 'advance_search'"
                        @keyup.enter="aspectbutton"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <!-- ถ้า 2 ตัวบน key_type ไม่เปลี่ยน dateให้ใช้ v-else ไปเลย -->
                  <v-layout row wrap justify-center fill-height v-if="item.key_type == 'date'"  >
                    <v-flex lg5 class="pa-1">
                      <v-dialog
                        v-model="aspect_datefrom[item.dialog_date_from]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="aspect_valuedatefrom[item.dialog_date_from]"
                            :value="item.date_from"
                            :color="color.theme"
                            :label="item.key_name"
                            :disabled="selectSearch == 'advance_search'"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                            @keyup.enter="aspectbutton"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @input="aspect_datefrom[item.dialog_date_from] = false"
                          v-model="aspect_valuedatefrom[item.dialog_date_from]"
                          :color="color.theme"
                          @keyup.enter="aspectbutton"
                        >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex lg5 class="pa-1">
                      <v-dialog
                        v-model="aspect_dateto[item.dialog_date_to]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                           
                          <v-text-field
                            v-model="aspect_valuedateto[item.dialog_date_to]"
                            :color="color.theme"
                            :disabled="selectSearch == 'advance_search'"
                            :label="$t('toolbar.searching.searchtodate')"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                           @keyup.enter="aspectbutton"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="aspect_valuedateto[item.dialog_date_to]"
                          @input="aspect_dateto[item.dialog_date_to] = false"
                          :color="color.theme"
                          @keyup.enter="aspectbutton"
                        ></v-date-picker>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>
             
              <!-- ปุ่ม -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Advance Search -->
                <v-btn
                  v-if="selectSearch == 'advance_search'"
                  @click="calladvancesearchdata"
                  :color="color.theme"
                  :dark="color.darkTheme"
                  :loading="processdelete"
                  >{{ $t("toolbar.searching.button") }}</v-btn
                >
                <!-- Aspect serach button -->
                <v-btn
                  v-if="selectSearch == 'aspect_search'"
                  @click="aspectbutton"
                  :color="color.theme"
                  :dark="color.darkTheme"
                  >{{ $t("toolbar.searching.button") }}</v-btn
                >
              </v-card-actions>
              <v-progress-linear :active="processdelete" indeterminate color="green"></v-progress-linear>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" lg="10" v-else>
          <v-text-field
            v-if="imageHeight >= 400"
            v-model="search"
            :label="$t('quicksearch')"
            append-icon="search"
            dense
            single-line
            solo-inverted
            flat
            clearable
            hide-details
            @input="$emit('inputdata', search)"
            :color="color.theme"
            @click:clear="$emit('loadfile')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <!-- <v-menu transition="slide-x-transition" left>
        <template v-slot:activator="{ on }">
           <v-btn fab text v-on="on" small  >
             <v-icon>mdi-palette</v-icon>
          </v-btn>
      </template>-->
      <!-- <v-list dense>
        <v-list-item v-for="(item, i) in colors" :key="i" @click="changecolor(item.data)">
            <v-list-item-content>
              <v-btn  elevation="1" fab x-small :color="item.name"></v-btn>
            </v-list-item-content>
           
          </v-list-item>
      </v-list>-->
      <!-- <v-layout><v-flex>
              <v-btn v-for="(item, i) in colors" :key="i" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
      </v-flex></v-layout>-->
      <!-- </v-menu> -->
      <v-menu transition="slide-y-transition" bottom close-on-click offset-y v-if="checkroutername !== 'document-management'">
        <template v-slot:activator="{ on }">
          <v-btn class="mr-2" fab text v-on="on" :disabled="permission_denied_account">
            <v-icon large :color="color.theme">mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click.stop="openfile_">
            <v-list-item-avatar>
              <v-icon size="30" color="blue-grey darken-4">mdi-file-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="color: #263238">{{ $t("uploadfile.name") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="opencreatefolder()">
            <v-list-item-avatar>
              <v-icon size="30" color="blue-grey darken-4">mdi-folder-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="color: #263238">{{ $t("mainRClick.uploadfolder") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="openfolder_">
            <v-list-item-avatar>
              <v-icon size="30" color="blue-grey darken-4">mdi-folder-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="color: #263238">{{ $t("uploadfolder.name") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider inset vertical></v-divider>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn text x-large v-on="on">
            <v-list-item-avatar v-if="dataCitizenProfile.picture == null">
              <!-- <img :src="'https://image.flaticon.com/icons/svg/149/149071.svg'" alt="John" /> -->
              <img src="@/assets/user.png" alt="John" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <img :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture" alt="John" />
            </v-list-item-avatar>
            <div class="text-left" v-if="imageHeight >= 500">
              <b style="color: #263238">{{ fullname }}</b>
              <br />
              <span style="font-size: 13px; color: #263238">{{
                $store.getters.dataTypeusercurrent($store.state.account_active["type"], $t("default"))
              }}</span>
            </div>
          </v-btn>
        </template>
        <v-card>
          <v-list nav>
            <v-list-item>
              <v-list-item-avatar v-if="dataCitizenProfile.picture == null">
                <!-- <img :src="'https://image.flaticon.com/icons/svg/149/149071.svg'" alt="John" /> -->
                <img src="@/assets/user.png" alt="John" />
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <img :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture" alt="John" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $store.getters.dataTypeusercurrent($store.state.account_active["type"], $t("default"))
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-menu transition="slide-x-transition" left>
                  <template v-slot:activator="{ on }">
                    <v-btn fab text v-on="on" small>
                      <v-icon>mdi-palette</v-icon>
                    </v-btn>
                  </template>
                  <v-layout style="background-color:white;" class="pa-1">
                    <v-flex>
                      <v-row no-gutters>
                        <v-subheader class="pa-0 pl-1">{{ $t("theme") }}</v-subheader>
                      </v-row>
                      <v-row no-gutters>
                        <v-divider></v-divider>
                        <v-col v-for="(item, n1) in color_1" :key="n1" class="pa-1">
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card>  -->
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data)"
                            class="elevation-4"
                            style="cursor: pointer"
                          >
                            <v-icon :dark="color.darkTheme" v-if="color.theme === item.name">done</v-icon>
                          </v-avatar>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col v-for="(item, n2) in color_2" :key="n2" class="pa-1">
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data)"
                            class="elevation-4"
                            style="cursor: pointer"
                          >
                            <v-icon :dark="color.darkTheme" v-if="color.theme === item.name">done</v-icon>
                          </v-avatar>
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col v-for="(item, n3) in color_3" :key="n3" class="pa-1">
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data)"
                            class="elevation-4"
                            style="cursor: pointer"
                          >
                            <v-icon :dark="color.darkTheme" v-if="color.theme === item.name">done</v-icon>
                          </v-avatar>
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                        </v-col>
                      </v-row>
                      <!-- <v-col>
                    <v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i<5" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>4 && i<10" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>9 && i<15" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>14 && i<20" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row>
                      </v-col>-->
                      <v-row>
                        <v-subheader class="pa-0 pl-4 pointer" @click="openColorpicker = true">{{
                          $t("toolbar.morecolor")
                        }}</v-subheader>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-menu>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <!-- เมนูบนขวา -->
          <v-list dense v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small>storage</v-icon>
                  {{ $t("toolbar.storage") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pt-0">
              <v-list-item-icon>
                <v-chip>
                  <v-icon small>person</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle class="text-center caption font-weight-light">
                  <v-progress-linear :color="percentagestoage_setcolor_" rounded :value="percentageStorage" height="15">
                    <strong class="caption font-weight-light">{{ percentageStorage }} %</strong>
                  </v-progress-linear>
                  {{ storage_usage }} in {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense v-else-if="dataAccountActive.type === 'Business'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small>storage</v-icon>
                  {{ $t("toolbar.storage") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pt-0">
              <v-list-item-icon>
                <v-chip>
                  <v-icon small>person</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle class="text-center caption font-weight-light">
                  <v-progress-linear :color="percentagestoagecitizeninbiz_setcolor_" rounded :value="percentageStoragecitizeninbiz" height="15">
                    <strong class="caption font-weight-light">{{ percentageStoragecitizeninbiz }} %</strong>
                  </v-progress-linear>
                  {{ storage_usage }} in {{ storage_max_personal_biz }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-chip>
                  <v-icon small>business</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle class="text-center caption font-weight-light">
                  <v-progress-linear
                    :color="percentagestoagebusiness_setcolor_"
                    rounded
                    :value="percentageStoragebusiness"
                    height="15"
                  >
                    <strong class="caption font-weight-light">{{ percentageStoragebusiness }} %</strong>
                  </v-progress-linear>
                  <!-- <br /> -->
                  {{ storage_usage_biz }} in {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-list>
              <v-list-item v-for="(item, i) in listcctv()" :key="i" link @click="linktomenu(item, choosetypeuser)">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-for="(item, i) in listitem()" :key="i" link @click="linktomenu(item, choosetypeuser)">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
             <v-divider></v-divider>
             <v-list class="text-center">
              <v-list-item justify-center>
                <v-list-item-content>
                  <v-list-item-title>{{version}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab text v-on="on" small>
            <flag :iso="defaultflag" :squared="false" />
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item v-for="(item, i) in language" :key="i" @click="changelang(item.data)">
            <v-list-item-action>
              <flag :iso="item.flag" :squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <dialogprofile :show="openprofile" @closedialog="openprofile = false"></dialogprofile>

    <uploadfiles
      :sendswitchapi="sendswitchapi"
      :show="openUploadfiles"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="closeDialog(), (openUploadfiles = false), $emit('callstorage'), $emit('openDrag')"
      @openuploadfile="openUploadfiles = true"
      @closeDrag="$emit('closeDrag')"
      @checkclosedialog="checkdialogupfile"
    ></uploadfiles>

    <createfolder
      :show="openUploadfolder"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="closeDialog(), (openUploadfolder = false)"
      @openuploadfolder="openUploadfolder = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @checkclosedialog="checkdialogupfile"
    ></createfolder>

    <uploadfolder
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      :enabled_inputfile="openUploadfolderinput"
      @closewithoutload="(openUploadfolderinput = false), $emit('openDrag')"
      @closeUpload="(openUploadfolderinput = false), checkdialogupfile(), $emit('openDrag'), closeDialog()"
    ></uploadfolder>

    <colorpicker
      :show="openColorpicker"
      :setColor="setcolor"
      @close="closeDialog(), (openColorpicker = false)"
      @openColorpicker="openColorpicker = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></colorpicker>
    <dialogcontactbuystorage
    :show="opendialogcontact"
    @closedialog="opendialogcontact = false"
    ></dialogcontactbuystorage>
    <shortcut :show="openshortcut" @closeshortcut="openshortcut = false"></shortcut>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "../../globalFunctions/generateAuthorize";
import formatDatetime from "../../globalFunctions/formatDatetime";
import format from "date-fns/format";
const createfolder = () => import("../upload/dialog-uploadfolder");
const uploadfiles = () => import("../upload/dialog-uploadfiles2");
const uploadfolder = () => import("../upload/input-uploadfolder");
const dialogprofile = () => import("../optional/dialog-profile");
const colorpicker = () => import("../optional/dialog-colorpicker");
const shortcut = () => import("../optional/dialog-shortcut");
const dialogcontactbuystorage = () => import("../optional/dialog-contactbuystorage");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["parentfolder", "quicksearch", "setcolor", "permissionfolder", "aspectSearch", "sendswitchapi", "departmentid"],
  components: { createfolder, uploadfolder, uploadfiles, dialogprofile, colorpicker,shortcut,dialogcontactbuystorage},
  data: () => ({
    opendialogcontact:false,
    openshortcut:false,
    checkdefault:false,
    version:"",
    processdelete: false,
    openUploadfolderinput: false,
    dialogupfile: false,
    aspect_datefrom: [],
    aspect_dateto: [],
    aspect_valuedatefrom: [],
    aspect_valuedateto: [],
    aspectDropdown: "",
    aspect_data: null,
    selectSearch: "advance_search",
    showDataStorage: "",
    disabledatesearch: false,
    permission_denied_account: true,
    checkbusiness: false,
    advancesearch: false,
    date_now:format(new Date(), "yyyy-MM-dd"),
    fromdate: "",
    menufromdate: false,
    todate: "",
    menutodate: false,
    optionSearch: [],
    type_Fulltextsearch: "content_text",
    typeSearch: "keywordsearch",
    type: "hex",
    hex: "#FF00FF",
    openprofile: false,
    search: "",
    cctv: [
      {
        active: true,
        icon: "mdi-cctv",
        text: "toolbar.cctv",
        link: "/cctv",
        disable: false,
      },
    ],
    forbusiness_inbox: [
      {
        active: true,
        icon: "mdi-inbox-arrow-down",
        text: "toolbar.allbusinessinbox",
        link: "/allbusinessinbox",
        disable: false,
      },
      // {
      //   active: true,
      //   icon: "mdi-inbox-arrow-up",
      //   text: "toolbar.allbusinessoutbox",
      //   link: "/allbusinessoutbox",
      //   disable: false,
      // },
    ],
    forbusiness_outbox: [
      // {
      //   active: true,
      //   icon: "mdi-inbox-arrow-down",
      //   text: "toolbar.allbusinessinbox",
      //   link: "/allbusinessinbox",
      //   disable: false,
      // },
      {
        active: true,
        icon: "mdi-inbox-arrow-up",
        text: "toolbar.allbusinessoutbox",
        link: "/allbusinessoutbox",
        disable: false,
      },
    ],
    library: [
      // {
      //   active: true,
      //   icon: "mdi-inbox-arrow-down",
      //   text: "toolbar.allbusinessinbox",
      //   link: "/allbusinessinbox",
      //   disable: false,
      // },
      // {
      //   active: true,
      //   icon: "mdi-book-open-page-variant",
      //   text: "toolbar.library",
      //   link: "/library",
      //   disable: true,
      // },
    ],
    counter: 0,
    defaultaccount: "ผู้ใช้งานทั่วไป",
    defaultflag: "",
    typeuser: [],
    choosetypeuser: {},
    language: [
      {
        flag: "us",
        name: "languages.english",
        data: "en",
      },
      {
        flag: "th",
        name: "languages.thai",
        data: "th",
      },
    ],
    dialog: false,
    drawer: null,
    tabs: 2,
    openUploadfiles: false,
    openUploadfolder: false,
    openColorpicker: false,
    items: [
      {
        active: true,
        icon: "cloud",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/:id",
        disable: false,
      },
      {
        active: true,
        icon: "cloud",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive",
        link: "/mydrive",
        disable: false,
      },
      {
        active: true,
        icon: "star",
        text: "toolbar.mystarfile",
        link: "/starred",
        disable: false,
      },
      {
        active: true,
        prependicon: "mdi-inbox-arrow-down",
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-inbox-arrow-down",
        text: "toolbar.myinbox.Parentname",
        model: false,
        children: [
          {
            icon: "face",
            text: "toolbar.myinbox.Personalname",
            link: "/myinboxsystem",
            type: 1,
          },
          {
            icon: "business",
            text: "toolbar.myinbox.Personalname",
            link: "/myinboxsystem",
            type: 2,
          },
        ],
      },
      {
        active: true,
        icon: "folder_shared",
        text: "toolbar.mysharefile",
        link: "/mydrive",
        disable: true,
      },
      {
        active: true,
        icon: "mdi-folder-clock",
        text: "toolbar.expiredfile",
        link: "/fileexpired",
        disable: false,
      },
      {
        active: true,
        icon: "delete_sweep",
        text: "toolbar.mytrash",
        link: "/trash",
        disable: false,
      },
    ],
    color_1: [
      {
        name: "#B71C1C",
        data: "#B71C1C",
      },
      {
        name: "#EF5F33",
        data: "#EF5F33",
      },
      {
        name: "#FFA000",
        data: "#FFA000",
      },
      {
        name: "#228B22",
        data: "#228B22",
      },
    ],
    color_2: [
      {
        name: "#7CB342",
        data: "#7CB342",
      },
      {
        name: "#000080",
        data: "#000080",
      },
      {
        name: "#1E90FF",
        data: "#1E90FF",
      },
      {
        name: "#622D90",
        data: "#622D90",
      },
    ],
    color_3: [
      {
        name: "#F17B9B",
        data: "#F17B9B",
      },
      {
        name: "#6D4C41",
        data: "#6D4C41",
      },
      {
        name: "#696969",
        data: "#696969",
      },
      {
        name: "#1976D2",
        data: "#1976D2",
      },
    ],
  }),
  created() {
    this.changelang(localStorage.getItem("lang"));
    this.changecolor(localStorage.getItem("color"));
    this.dataTypeUser();

    //this.checkbusinessfeature(this.choosetypeuser);
  },
  filters: {
    subStr: function(val) {
      if (val.length > 22) return val.substring(0, 22) + "...";
      else return val;
    },
  },
  computed: {
    ...mapState([
      "color",
      "status_permission_outbox",
      "status_permission_inbox",
      "feature_status",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
      "storage_max_personal_biz"
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataAccountCCTV",
      "dataStorageMaxpersonalinbiz",
      "dataLoginDefault",
    ]),
    checkroutername() {
      return this.$router.app["_route"]["name"] || "";
    },
    percentageStorage() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage) / this.convertstorageToByte(this.dataStorageMax)) * 100;

      self.showDataStorage = self.dataStorageMax;

      return percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
    },
    percentageStoragecitizeninbiz() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage) / this.convertstorageToByte(this.dataStorageMaxpersonalinbiz)) * 100;

      self.showDataStorage = self.dataStorageMaxpersonalinbiz;

      return percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
    },
    // setcolor storage citizen  ที่ แถบเมนูด้านล่างซ้าย
    percentagestoage_setcolor() {
      if (Math.ceil(this.percentageStorage) >= 0 && Math.ceil(this.percentageStorage) < 70) {
        return "white";
      } else if (Math.ceil(this.percentageStorage) >= 70 && Math.ceil(this.percentageStorage) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStorage) >= 90) {
        return "red";
      }
    },
    // setcolor storage business  ที่ แถบเมนูบนขวา
    percentagestoage_setcolor_() {
      if (Math.ceil(this.percentageStorage) >= 0 && Math.ceil(this.percentageStorage) < 70) {
        // return 'green'
        return this.color.theme;
      } else if (Math.ceil(this.percentageStorage) >= 70 && Math.ceil(this.percentageStorage) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStorage) >= 90) {
        return "red";
      }
    },
    // setcolor storage citizen in biz ที่ แถบเมนูด้านบนขวา
    percentageStoragebusiness() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage_biz) / this.convertstorageToByte(this.dataStorageMax)) * 100;

      self.showDataStorage = self.dataStorageMax;

      return percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
    },
    // setcolor storage citizen  ที่ แถบเมนูด้านล่างซ้าย
    percentagestoagebusiness_setcolor() {
      if (Math.ceil(this.percentageStoragebusiness) >= 0 && Math.ceil(this.percentageStoragebusiness) < 70) {
        return "white";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 70 && Math.ceil(this.percentageStoragebusiness) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 90) {
        return "red";
      }
    },
    // setcolor storage citizen in biz  ที่ แถบเมนูด้านล่างซ้าย
    percentagestoagecitizeninbiz_setcolor() {
      if (Math.ceil(this.percentageStoragecitizeninbiz) >= 0 && Math.ceil(this.percentageStoragecitizeninbiz) < 70) {
        return "white";
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 70 && Math.ceil(this.percentageStoragecitizeninbiz) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 90) {
        return "red";
      }
    },
    // setcolor storage biz  ที่ แถบเมนูด้านล่างซ้าย (ชั่วคราว)
     percentagestoagebiz_setcolor_right() {
      if (Math.ceil(this.percentageStoragebusiness) >= 0 && Math.ceil(this.percentageStoragebusiness) < 70) {
        return "white";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 70 && Math.ceil(this.percentageStoragebusiness) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 90) {
        return "red";
      }
    },
    // setcolor storage citizen in biz ที่ แถบเมนูด้านบนขวา
    percentagestoagecitizeninbiz_setcolor_() {
      if (Math.ceil(this.percentageStoragecitizeninbiz) >= 0 && Math.ceil(this.percentageStoragecitizeninbiz) < 70) {
        return this.color.theme;
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 70 && Math.ceil(this.percentageStoragecitizeninbiz) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 90) {
        return "red";
      }
    },
    // setcolor storage business  ที่ แถบเมนูบนขวา
    percentagestoagebusiness_setcolor_() {
      if (Math.ceil(this.percentageStoragebusiness) >= 0 && Math.ceil(this.percentageStoragebusiness) < 70) {
        return this.color.theme;
        // return 'green'
      } else if (Math.ceil(this.percentageStoragebusiness) >= 70 && Math.ceil(this.percentageStoragebusiness) < 90) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 90) {
        return "red";
      }
    },

    _oldpercentageStorage() {
      let self = this;
      let _dataStorageMax = [];
      // console.log("tttt", this.dataStorageMax);

      // if(){}
      let datastorage_max = this.dataStorageMax.split(" ");
      // console.log("datastorage_max", datastorage_max);

      if (datastorage_max[1] === "KB") {
        if (datastorage_max[0] / 1048576 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1048576));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0] / 1048576).toFixed(2)) + " " + "GB";
        } else {
          _dataStorageMax.push(String(datastorage_max[0]));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "KB";
        }
      } else if (datastorage_max[1] === "MB") {
        if (datastorage_max[0] / 1024 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1024));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0] / 1024).toFixed(2)) + " " + "GB";
        } else {
          _dataStorageMax = this.dataStorageMax.split(" ");
          self.showDataStorage = String(datastorage_max[0]) + " " + "MB";
        }
      } else if (datastorage_max[1] === "GB") {
        _dataStorageMax = this.dataStorageMax.split(" ");
        self.showDataStorage = String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "GB";
      }
      console.log("Ddd", _dataStorageMax);

      // console.log("citizen---",this.storage_usage);
      // console.log("business---",this.storage_usage_biz);

      let _dataStorageUsage = this.storage_usage.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage);
        // console.log(max_storage);
        let total = (current_storage / max_storage) * 100;
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total) || 100;
        }
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total) || 100;
        }
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        if (max_storage === 0 && current_storage === 0) {
          return 0;
        }
        let total = (current_storage / max_storage) * 100;
        // console.log("t------",total);
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total);
        }
      }
    },

    _oldpercentageStoragebusiness() {
      let self = this;
      let _dataStorageMax = [];
      console.log("tttt", this.dataStorageMax);
      let datastorage_max = this.dataStorageMax.split(" ");
      console.log("datastorage_max", datastorage_max);

      if (datastorage_max[1] === "KB") {
        if (datastorage_max[0] / 1048576 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1048576));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0] / 1048576).toFixed(2)) + " " + "GB";
        } else {
          _dataStorageMax.push(String(datastorage_max[0]));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "KB";
        }
      } else if (datastorage_max[1] === "MB") {
        if (datastorage_max[0] / 1024 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1024));
          _dataStorageMax.push("GB");
          self.showDataStorage = String(parseFloat(datastorage_max[0] / 1024).toFixed(2)) + " " + "GB";
        } else {
          _dataStorageMax = this.dataStorageMax.split(" ");
          self.showDataStorage = String(datastorage_max[0]) + " " + "MB";
        }
      } else if (datastorage_max[1] === "GB") {
        _dataStorageMax = this.dataStorageMax.split(" ");
        self.showDataStorage = String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "GB";
      }

      // console.log("Ddd", _dataStorageMax);
      // console.log("bbbbb", this.storage_usage_biz);
      let _dataStorageUsage = this.storage_usage_biz.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total) || 100;
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total) || 100;
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        if (max_storage === 0 && current_storage === 0) {
          return 0;
        }
        let total = (current_storage / max_storage) * 100;
        // console.log("totalb----",total);
        if (total === Infinity) {
          return 100;
        } else {
          // console.log("t------",total);
          return Math.ceil(total);
        }
      }
    },

    fullname() {
      console.log(this.$store.getters.dataCitizenProfile);
      if (this.$t("default") === "th") {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      } else {
        if(this.$store.getters.dataCitizenProfile.hasOwnProperty('first_name_eng') === true){
        return (
          this.$store.getters.dataCitizenProfile["first_name_eng"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_eng"] || ""
        );
        }else{
          return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
        }
      }
    },

    imageHeight() {
      let self = this;
      let resolution = 0;
      switch (self.$vuetify.breakpoint.name) {
        case "xs":
          resolution = 220;
          break;
        case "sm":
          resolution = 400;
          break;
        case "md":
          resolution = 500;
          break;
        case "lg":
          resolution = 600;
          break;
        case "xl":
          resolution = 800;
          break;
      }
      return resolution;
    },

    ismydrive() {
      return this.$router.app["_route"]["name"] == "myfiles";
    },
    // sortColor() {
    //   let self = this;
    //   for (let i = 0; i < self.colors.length; i++) {
    //     return self.colors[i];
    //   }
    // },
    colorset: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },

    showColor() {
      if (typeof this.colorset === "string") return this.colorset;

      return JSON.stringify(
        Object.keys(this.colorset).reduce((colorset, key) => {
          colorset[key] = Number(this.colorset[key].toFixed(2));
          return colorset;
        }, {}),
        null,
        2
      );
    },
  },
  methods: {
    gotocontactpayment(){
      // this.$router.push({ path: "/contactpayment" });
      this.opendialogcontact = true;
    },
     fn_shortcut(){

      this.openshortcut = true
    },
    test(){
      console.log("กดenter",);
    },
    checkdialogupfile(){
      this.dialogupfile = true;
    },
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },

    reloadtomydrive() {
      if (this.dataAccountActive["type"] === "Business") {
        let data_buffer = sessionStorage.getItem("biz_active");
        let data_biz = JSON.parse(data_buffer);
        console.log(data_biz);

        this.$store.dispatch("switchaccount_business", data_biz).then((msg) => {
          //this.$emit("loadfile");
          //this.$emit("callstorage");
          if (msg.status === "Business Access Granted") {
           // if()
            //this.$router.push({ name: "myfiles" });
            this.$router.push({ path: "/directory/" + this.dataAccountActive.business_info["my_folder_id"] });
          } else {
            this.$router.push("logout");
          }
        });
      } else {
        this.$router.push({ name: "myfiles" });
      }
    },

    viewLog(val) {
      console.log("View log =>", val);
    },

   async aspectbutton() {
      let aspect_keys = [];
      let aspect_values = {};
      let index_dt = 0;
      for (let index = 0; index < this.aspect_data[0].aspect_key.length; index++) {
        aspect_keys.push({
          key_name: this.aspect_data[0].aspect_key[index].key_name,
          key_type: this.aspect_data[0].aspect_key[index].key_type,
        });
        let objname = this.aspect_data[0].aspect_key[index].key_name;
        // อาจจะต้องดักแค่อันที่ไม่ใช่ date
        // ตัดตัวใช้ if ทิ้ง
        // ให้ไปใช้ตัว else เลย
        if (
          this.aspect_data[0].aspect_key[index].key_type == "varchar" ||
          this.aspect_data[0].aspect_key[index].key_type == "dropdown"
        ) {
          Object.assign(aspect_values, {
            [objname]: this.aspect_data[0].aspect_key[index].select_data,
          });
        } else if (this.aspect_data[0].aspect_key[index].key_type == "date") {
          // แต่ละ บริษัท ใช้ keytype ไม่เหมือนกันต้องดักเพิ่ม
          // date docdate day days
          aspect_keys[index].key_type = "docdate";
          let aspect_search_date = {
            date_from: this.aspect_valuedatefrom[index_dt],
            date_to: this.aspect_valuedateto[index_dt],
          };
          Object.assign(aspect_values, { [objname]: aspect_search_date });
          index_dt += 1;
        } else {
          Object.assign(aspect_values, {
            [objname]: this.aspect_data[0].aspect_key[index].select_data,
          });
        }
      }
      console.log("aspect_values key and value", aspect_values);

      let payload = {
        account_id: this.dataAccountId,
        aspect_keys: aspect_keys,
        aspect_value: aspect_values,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/aspect_search", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          if (response.data.status == "OK") {
            // console.log(response);
            this.$emit("advancesearch", response);
          } else {
            // console.log("response.data != OK", response);
            this.$emit("advancesearch", response);
            console.log(response.data);
          }
          // ปิด v-menu
          this.advancesearch = false;
        })
        .catch((error) => {
          console.log("error aspect search", error);
        });
    },

    setAspectData() {
      this.aspect_data = this.aspectSearch || [{ aspect: "False", aspect_key: [] }];
      // console.log('aspectdata',this.aspect_data)
      let index_dt = 0;
      for (let index = 0; index < this.aspect_data[0].aspect_key.length; index++) {
        // ถ้า key_type varchar กับ dropdrow ไม่เปลี่ยน
        // ให้ if date เพราะ date ไมรู้จะส่งมาแบบไหน varchar กับ dropdrow ให้เป็น else ปกติ
        if (this.aspect_data[0].aspect_key[index].key_type == "varchar") {
          this.aspect_data[0].aspect_key[index].select_data = "";
        } else if (this.aspect_data[0].aspect_key[index].key_type == "date") {
          //สำหรับระบุตำแหน่ง dialog date
          this.aspect_data[0].aspect_key[index].dialog_date_from = index_dt;
          this.aspect_data[0].aspect_key[index].dialog_date_to = index_dt;
          this.aspect_datefrom.push(false);
          this.aspect_dateto.push(false);
          this.aspect_valuedatefrom.push("");
          this.aspect_valuedateto.push("");
          index_dt += 1;
        } else if (this.aspect_data[0].aspect_key[index].key_type == "dropdown") {
          this.aspect_data[0].aspect_key[index].select_data = "";
        }
      }
      // console.log('Add key aspect data',this.aspect_data)
    },

    updateAspectData(index, value) {
      this.aspect_data[0].aspect_key[index].select_data = value;
    },

    changestorage_sidebar(_choosetypeuser) {
       console.log(_choosetypeuser);
       console.log("this.checkdefault",this.checkdefault);
       this.checkdefault = true
      // let beforechoose = this.choosetypeuser;
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (_choosetypeuser.type === "Citizen") {
        this.checkbusiness = false;
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          console.log(msg);
          this.$emit("loadfile");
          this.$emit("callstorage");
          sessionStorage.removeItem("biz_active");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          this.checkbusiness = true;
          let payload = {
            taxid: _choosetypeuser.data.id_card_num,
            accesstoken: this.dataAccesstoken,
            biz_id:_choosetypeuser.data.id,
            branch_no: _choosetypeuser.data.branch_no
          };

          this.$store.dispatch("switchaccount_business", payload).then((msg) => {
            if (msg["status"] === "Business Access Denied") {
              this.checkbusiness = false;
              console.log("msg", msg);
              if (msg["msg"] === "ER401: Authorization is wrong") {
                this.$router.push("logout");
              } else {
                if (this.dataAccountActive["type"] === "Business") {
                  let type_business_index = this.typeuser.findIndex((x) => x.id === this.dataAccountActive["business_info"]["id"]);
                  this.choosetypeuser = this.typeuser[type_business_index];
                  this.checkbusiness = false;
                  console.log("feature", this.feature_status);
                } else {
                  this.choosetypeuser = {
                    type: "Citizen",
                    data: [],
                    nameth: "ผู้ใช้งานทั่วไป",
                    nameen: "Personal",
                  };
                }
                Toast.fire({ icon: "error", title: msg["msg"] });
                // console.log("vvv",this.dataAccountActive.business_info["my_folder_id"]);
                this.$emit("loadfile");
                //this.$emit("loadfile_reload");
                this.$emit("callstorage");
                this.$router.push({ name: "myfiles" });
                // this.$store.dispatch("switchaccount_citizen").then(msg => {
                //   this.$emit("loadfile");
                //   this.$emit("callstorage");
                //   this.$router.push({ name: "myfiles" });
                // });
              }
            } else {
              console.log("feature", this.feature_status);
              this.checkbusiness = true;
              this.choosetypeuser = _choosetypeuser;
              // console.log(msg);
              // this.$emit("loadfile");
              this.$emit("callstorage");
               console.log("vvv",this.dataAccountActive.business_info["my_folder_id"]);
                this.$emit("loadfile",this.dataAccountActive.business_info["my_folder_id"]);
                this.$emit("loadfile_reload");
              //this.$router.push({ name: "myfiles" });
              this.$router.push({ path: "/directory/" + this.dataAccountActive.business_info["my_folder_id"] });
            }
          });
        } else {
          this.$store.dispatch("switchaccount_businessonemail", _choosetypeuser).then((msg) => {
            console.log(msg);
            this.$emit("loadfile");
            this.$emit("callstorage");
            this.$router.push({ name: "myfiles" });
          });
        }
      }
    },


    openfile() {
      console.log("rot", this.$router.currentRoute.path);
      if (this.$router.currentRoute.path === "/sharedwithme") {
        Toast.fire({
          icon: "error",
          title: this.$t("uploadfile.donotshare"),
        });
      } else {
        this.dialogupfile = false;
        (this.openUploadfiles = true), this.$emit("callparentfolder");
      }
    },

    openfolder() {
      if (this.$router.currentRoute.path === "/sharedwithme") {
        Toast.fire({
          icon: "error",
          title: this.$t("uploadfolder.donotshare"),
        });
      } else {
        this.dialogupfile = false;
        (this.openUploadfolder = true), this.$emit("callparentfolder");
      }
    },
    opencreatefolder() {
      if (this.$router.currentRoute.path === "/sharedwithme") {
        Toast.fire({
          icon: "error",
          title: this.$t("uploadfolder.donotshare"),
        });
      } else {
        this.dialogupfile = false;
        this.$emit("closeDrag");
        (this.openUploadfolderinput = true), this.$emit("callparentfolder");
      }
    },
    openfile_() {
      this.openUploadfiles = true;
      this.dialogupfile = false;
      this.$emit("callparentfolder");
    },
    openfolder_() {
      this.openUploadfolder = true;
      this.dialogupfile = false;
      this.$emit("callparentfolder");
    },

    openpro() {
      this.openprofile = true;
    },

    searchdata() {
      // if (this.typeSearch == "fulltextsearch") {
      //   this.$emit("fulltextsearch", {
      //     keyword: this.search,
      //     type: this.type_Fulltextsearch
      //   });
      // } else {
      //   this.$emit("inputdata", this.search);
      // }
    },

    async calladvancesearchdata() {
      let auth = await gbfGenerate.generateToken();
      let payload = {};
      let acc_id = "";

      if (this.dataAccountActive["type"] === "Business") {
        acc_id = this.dataAccountActive["business_info"]["business_id"];
      } else {
        acc_id = this.dataAccountId;
      }
      payload = {
        account_id: this.dataAccountId,
        cre_dtm: formatDatetime.formatDateYYYYmmDD(this.fromdate, "from"),
        to_dtm: formatDatetime.formatDateYYYYmmDD(this.todate, "to"),
        type_search: this.optionSearch,
        name: this.search,
        cctv:this.dataAccountCCTV["status"]
      };
      // console.log("fake payload", payload);
      this.processdelete = true;
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + `/api/v2/advanced_search`, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("advance search", response.data);
          if(response.data.status === "OK"){
            this.$emit("advancesearch", response)
          }else{
            console.log('ERR')
            this.$emit("advancesearch", response)
          }
          this.advancesearch = false;
          this.processdelete = false;
        });

      // console.log("****", this.type_Fulltextsearch);
      // this.processdelete = true;
      // if (this.type_Fulltextsearch === "file_name") {
      //   if (this.search === "" || this.search === null) {
      //     //  this.$emit("advancesearch",{ type_search: "03",cre_dtm_from: this.fromdate,  cre_dtm_to: this.todate, account_id: this.dataAccountId})
      //     payload = {
      //       type_search: "03",
      //       cre_dtm_from: this.fromdate,
      //       cre_dtm_to: this.todate,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //   } else if (this.fromdate !== "" && this.fromdate !== null && this.todate !== "" && this.todate !== null) {
      //     // this.$emit("advancesearch",{ type_search: "05",cre_dtm_from: this.fromdate,  cre_dtm_to: this.todate, file_name: this.search , account_id: this.dataAccountId})
      //     payload = {
      //       type_search: "05",
      //       cre_dtm_from: this.fromdate,
      //       cre_dtm_to: this.todate,
      //       name: this.search,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //   } else {
      //     payload = {
      //       type_search: "01",
      //       name: this.search,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //     // this.$emit("advancesearch",{ type_search: "01", file_name: this.search, account_id: this.dataAccountId})
      //   }
      //   this.axios
      //     .post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/advanced_search", payload)
      //     .then((response) => {
      //       if (response.data.status == "OK") {
      //         console.log(response);

      //         this.$emit("advancesearch", response);
      //       } else {
      //         console.log(response);
      //         this.$emit("advancesearch", response);
      //         console.log(response.data);
      //       }
      //       this.advancesearch = false;
      //       this.processdelete = false;
      //     })
      //     .catch((error) => {
      //       this.processdelete = false;
      //       console.log(error);
      //     });
      // } else if (this.type_Fulltextsearch === "folder") {
      //   // this.$emit("inputdata", this.search);
      //   // this.advancesearch = false;
      //   if (this.search === "" || this.search === null) {
      //     payload = {
      //       type_search: "09",
      //       cre_dtm_from: this.fromdate,
      //       cre_dtm_to: this.todate,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //   } else if (this.fromdate !== "" && this.fromdate !== null && this.todate !== "" && this.todate !== null) {
      //     payload = {
      //       type_search: "10",
      //       cre_dtm_from: this.fromdate,
      //       cre_dtm_to: this.todate,
      //       name: this.search,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //   } else {
      //     payload = {
      //       type_search: "08",
      //       name: this.search,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //   }
      //   this.axios
      //     .post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/advanced_search", payload)
      //     .then((response) => {
      //       if (response.data.status == "OK") {
      //         console.log(response);

      //         this.$emit("advancesearch", response);
      //       } else {
      //         console.log(response);
      //         this.$emit("advancesearch", response);
      //         console.log(response.data);
      //       }
      //       this.advancesearch = false;
      //       this.processdelete = false;
      //     })
      //     .catch((error) => {
      //       this.processdelete = false;
      //       console.log(error);
      //     });
      // } else if (this.type_Fulltextsearch === "hashtag") {
      //   payload = {
      //     type_search: "11",
      //     name: this.search,
      //     account_business_id: acc_id,
      //     user_id: this.dataUsername,
      //     account_id: this.dataAccountId,
      //   };
      //   this.axios
      //     .post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/advanced_search", payload)
      //     .then((response) => {
      //       if (response.data.status == "OK") {
      //         console.log(response);
      //         this.$emit("advancesearch", response);
      //       } else {
      //         console.log(response);
      //         this.$emit("advancesearch", response);
      //         console.log(response.data);
      //       }
      //       this.advancesearch = false;
      //       this.processdelete = false;
      //     })
      //     .catch((error) => {
      //       this.processdelete = false;
      //       console.log(error);
      //     });
      // } else {
      //   if (this.search === "" || this.search === null) {
      //     payload = {
      //       type_search: "03",
      //       cre_dtm_from: this.fromdate,
      //       cre_dtm_to: this.todate,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //     //  this.$emit("advancesearch",{ type_search: "03",cre_dtm_from: this.fromdate,  cre_dtm_to: this.todate, account_id: this.dataAccountId})
      //   } else if (this.fromdate !== "" && this.fromdate !== null && this.todate !== "" && this.todate !== null) {
      //     payload = {
      //       type_search: "06",
      //       cre_dtm_from: this.fromdate,
      //       cre_dtm_to: this.todate,
      //       text_data: this.search,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //     // this.$emit("advancesearch",{ type_search: "06",cre_dtm_from: this.fromdate,  cre_dtm_to: this.todate, text_data: this.search , account_id: this.dataAccountId})
      //   } else {
      //     payload = {
      //       type_search: "02",
      //       text_data: this.search,
      //       account_business_id: acc_id,
      //       user_id: this.dataUsername,
      //       account_id: this.dataAccountId,
      //     };
      //     // this.$emit("advancesearch",{ type_search: "02", text_data: this.search, account_id: this.dataAccountId})
      //   }
      //   this.axios
      //     .post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/advanced_search", payload)
      //     .then((response) => {
      //       if (response.data.status == "OK") {
      //         console.log(response);
      //         this.$emit("advancesearch", response);
      //       } else {
      //         console.log(response);
      //         this.$emit("advancesearch", response);
      //         console.log(response.data);
      //       }
      //       this.advancesearch = false;
      //       this.processdelete = false;
      //     })
      //     .catch((error) => {
      //       this.processdelete = false;
      //       console.log(error);
      //     });
      // }
    },

    // ตั้ง icon file/folder
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    } else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    sortedArray(_rawdata){
      function compare(a,b){
        if(localStorage.getItem("lang") === 'en'){
          // decimal code ของ A กับ a ไม่เท่ากัน
          if(a.nameen.toUpperCase() < b.nameen.toUpperCase()){
            return -1
          }else {
            return 1
          }
        } else if (localStorage.getItem("lang") === 'th') {
          if(a.nameth < b.nameth){
            return -1
          }else {
            return 1
          }
        }
      }
      console.log('compare',_rawdata.sort(compare))
      return _rawdata.sort(compare)
    },
     dataTypeUser() {
      console.log('create dataTypeUser',localStorage.getItem("lang"))
      this.typeuser = []
      console.log(this.$store.state.service);
      if (this.$store.state.service !== "OneMail" && this.$store.state.service !== "OneMail_OneID") {
        let _citizen = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
          taxid: "",
        };

        this.typeuser.push(_citizen);
      } else {
        if (this.dataBusinessProfile.length === 0) {
          let _citizen = {
            type: "Citizen",
            data: [],
            nameth: "ผู้ใช้งานทั่วไป",
            nameen: "Personal",
            taxid: "",
            branch_no: ""
          };
          this.typeuser.push(_citizen);
        }
      }
      console.log('typeuser',this.typeuser)
      let rawtypeuser = []
      let i = 0;
      for (i = 0; i < this.dataBusinessProfile.length; i++) {
        if (this.$store.state.service !== "OneMail") {
          rawtypeuser.push({
            type: "Business",
            data: this.dataBusinessProfile[i],
            nameth: this.dataBusinessProfile[i]["first_name_th"],
            nameen: this.dataBusinessProfile[i]["first_name_eng"],
            taxid: this.dataBusinessProfile[i]["id_card_num"],
            biz_id:this.dataBusinessProfile[i]["id"],
            branch_no:this.dataBusinessProfile[i]["branch_no"]
          });
        } else {
          rawtypeuser.push({
            type: "Business",
            data: this.dataBusinessProfile[i],
            nameth: this.dataBusinessProfile[i]["first_name_th"],
            nameen: this.dataBusinessProfile[i]["first_name_eng"],
            taxid: this.dataBusinessProfile[i]["taxid"],
            biz_id:this.dataBusinessProfile[i]["id"],
            branch_no:this.dataBusinessProfile[i]["branch_no"]
          });
        }
      }
      rawtypeuser = this.sortedArray(rawtypeuser)
      // console.log('rawtypeuser sort',rawtypeuser)
      this.typeuser = this.typeuser.concat(rawtypeuser)
      console.log("this.typeuser",this.typeuser);
      // console.log('after sort',this.typeuser)
      console.log(this.choosetypeuser); 
      console.log("this.dataLoginDefault",this.dataLoginDefault["account_category"],this.checkdefault);
    //  if(this.dataLoginDefault["account_category"] === "Business" && this.checkdefault === false){
    //    this.checkbusiness = true;
    //       let payload = {
    //         taxid: this.dataLoginDefault.id_card_num,
    //         accesstoken: this.dataAccesstoken,
    //         biz_id:this.dataLoginDefault.id
            
    //       };
    //    this.$store.dispatch("switchaccount_business", payload).then((msg) => {
    //         if (msg["status"] === "Business Access Denied") {
    //           this.checkbusiness = false;
    //           console.log("msg", msg);
    //           if (msg["msg"] === "ER401: Authorization is wrong") {
    //             this.$router.push("logout");
    //           } else {
    //             if (this.dataAccountActive["type"] === "Business") {
    //               let type_business_index = this.typeuser.findIndex((x) => x.id === this.dataAccountActive["business_info"]["id"]);
    //               this.choosetypeuser = this.typeuser[type_business_index];
    //               this.checkbusiness = false;
    //               console.log("feature", this.feature_status);
    //             } else {
    //               this.choosetypeuser = {
    //                 type: "Citizen",
    //                 data: [],
    //                 nameth: "ผู้ใช้งานทั่วไป",
    //                 nameen: "Personal",
    //               };
    //             }
    //             Toast.fire({ icon: "error", title: msg["msg"] });
    //             this.$emit("loadfile");
    //             this.$emit("callstorage");
    //             console.log("เข้าอันนี้");
    //             //this.$router.push({ link: "/directory/" + this.dataLoginDefault["my_folder_id"] });
    //             this.$router.push({ name: "myfiles" });
    //             // this.$store.dispatch("switchaccount_citizen").then(msg => {
    //             //   this.$emit("loadfile");
    //             //   this.$emit("callstorage");
    //             //   this.$router.push({ name: "myfiles" });
    //             // });
    //           }
    //         } else {
    //           console.log("feature", this.feature_status);
    //           this.checkbusiness = true;
    //           let objIndex = this.typeuser.findIndex((obj) => obj.biz_id === this.dataLoginDefault.id);
    //           this.choosetypeuser = this.typeuser[objIndex]
    //           // console.log(msg);
    //           this.$emit("loadfile");
    //           this.$emit("callstorage");
    //           console.log("/directory/");
    //           this.$router.push({ path: "/directory/" + this.dataLoginDefault["my_folder_id"] });
    //           //this.$router.push({ name: "myfiles" });
    //         }
    //       });
    //  }
      if (this.dataAccountActive["type"] === "Business") {
        let type_business_index = -1;
        if(this.$store.state.service !== "OneMail"){
          type_business_index = this.typeuser.findIndex((x) => x.biz_id === this.dataAccountActive["business_info"]["id"]);
        }else{
        type_business_index = this.typeuser.findIndex((x) => x.taxid === this.dataAccountActive["taxid"]);
        }
        this.choosetypeuser = this.typeuser[type_business_index];
        this.checkbusiness = true;
      } else {
        this.choosetypeuser = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
          biz_id:""
        };
      }
    },

    changestorage(typeuser, business) {
      if (typeuser === "Citizen") {
        this.checkbusiness = false;
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          console.log(msg);
          this.$emit("loadfile");
          this.$emit("callstorage");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          this.checkbusiness = true;
          let payload = {
            taxid: business.id_card_num,
            accesstoken: this.dataAccesstoken,
            
          };
          this.$store.dispatch("switchaccount_business", payload).then((msg) => {
            // console.log(msg);
            if (msg["status"] === "Business Access Denied") {
              if (msg["msg"] === "ER401: Authorization is wrong") {
                this.$router.push("logout");
              } else {
                Toast.fire({ icon: "error", title: msg["msg"] });
              }
            } else {
              // console.log(msg);
              this.$emit("loadfile");
              this.$emit("callstorage");
              this.$router.push({ name: "myfiles" });
            }
          });
        } else {
          this.$store.dispatch("switchaccount_businessonemail", typeuser).then((msg) => {
            console.log(msg);
            this.$emit("loadfile");

            this.$emit("callstorage");
            this.$router.push({ name: "myfiles" });
          });
        }
      }
    },

    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
      this.dataTypeUser()
    },

    changecolor(parameter) {
      if (parameter === null) {
        localStorage.setItem("color", "primary");
      } else {
        localStorage.setItem("color", parameter);
      }
      //console.log("color",parameter);
      this.$store.dispatch("check_color", localStorage.getItem("color")).then();
    },

    gotoAdmin(choosetypeuser) {
      // console.log("ddddfdfdddf", choosetypeuser);
      let payload = {
        account_id: this.dataAccountId,
      };
      this.$store.dispatch("authorize_login_admin_business", payload).then((msg) => {
        // console.log(msg);
        if (msg["status"] === "Login Admin Business Access Denied") {
          // if (msg["msg"] === "ER401: Authorization is wrong") {
          //   this.$router.push("logout");
          // } else {
          //   Toast.fire({ icon: "error", title: msg["msg"] });
          // }
          console.log("Login Admin Business Access Denied");
          Toast.fire({ icon: "error", title: msg["msg"] });
        } else {
          console.log("login success");
          //this.$emit("loadfile");
          //this.$emit("callstorage");
          this.$router.push({ path: "/system-admin-index" });
        }
      });
    },
    // gotoAdminonebox() {
    //   this.$router.push({ path: "/adminonebox" });
    // },
    tolink(parameter, text) {
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (text === "toolbar.myinbox.Personalname") {
        this.$router.push({ path: parameter, query: { type: 1 } });
      } else if (text === "toolbar.myinbox.Businessname") {
        this.$router.push({ path: parameter, query: { type: 2 } });
      } else if (text === "toolbar.myoutbox.Businessname") {
        this.$router.push({ path: parameter, query: { type: 2 } });
      } else if (text === "toolbar.myoutbox.Personalname") {
        this.$router.push({ path: parameter, query: { type: 1 } });
      }else {
        this.$router.push(parameter);
      }
    },
    tolink_parent(parameter, text) {
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      console.log("tolink_parent");
       if(text === "toolbar.mydrive_bu"){
        this.$router.push( "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],);
        this.$emit("loadfile");
       }
        this.$router.push(parameter);
      
    },

    linktomenu(parameter, choosetypeuser) {
      console.log(parameter);
      if (parameter.key === "profile") {
        this.openpro();
      } else if (parameter.key === "admin") {
        this.gotoAdmin(choosetypeuser);
      // } else if (parameter.key === "consent") {
      //   this.$router.push({ path: "/consent" });
      } else if (parameter.key === "cctv") {
        window.open("https://eyeoncloud.net/member/login/");
      } else {
        this.$router.push({ path: "/logout" });
      }
    },

    closeDialog() {
      this.openUploadfiles = false;
      this.openUploadfolder = false;
      this.openColorpicker = false;
      console.log("this.dialogupfile", this.dialogupfile);
      if (this.dialogupfile === true) {
        this.$emit("loadfile");
      }
    },
    tolink_parent(parameter, text) {
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      console.log("tolink_parent");
       if(text === "toolbar.mydrive_bu"){
        this.$router.push( "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],);
        this.$emit("loadfile");
       }
        this.$router.push(parameter);
      
    },

    listmenu() {
      //  console.log("admin",this.role_level);
      // console.log("feature_status",this.feature_status);
      let _items = [];
      // console.log("type",this.dataAccountActive["type"]);
      // console.log("permissioninout",this.status_permission);

      if (this.dataAccountActive["type"] === "Business") {
        if (this.status_permission_inbox === "DE" && this.status_permission_outbox === "AL") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myinbox.Businessname", ///////
                //   link: "/myinbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "DE" && this.status_permission_outbox === "AD") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myinbox.Businessname", ///////
                //   link: "/myinbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                {
                  icon: "delete_sweep",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "DE" && this.status_permission_outbox === "DE") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myinbox.Businessname", ///////
                //   link: "/myinbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myoutbox.Businessname", //////
                //   link: "/myoutbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AL" && this.status_permission_outbox === "DE") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myoutbox.Businessname", //////
                //   link: "/myoutbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AD" && this.status_permission_outbox === "DE") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
        
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                {
                  icon: "delete_sweep",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                // {
                //   icon: "business",
                //   text: "toolbar.myoutbox.Businessname", //////
                //   link: "/myoutbox-system",
                //   type: 2,
                // },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AD" && this.status_permission_outbox === "AD") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
        
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // inbox123
                {
                  icon: "delete_sweep",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                },
                // {
                //   icon: "date_range",
                //   text: "ไฟล์อินบ๊อกซ์หมดอายุ",
                //   link: "/fileexpiredinbox",
                //   type: 4,
                // },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // outbox123
                {
                  icon: "delete_sweep",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                },
                // {
                //   icon: "date_range",
                //   text: "ไฟลเอาท์บ๊อกซ์หมดอายุ",
                //   link: "/fileexpiredoutbox",
                //   type: 4,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AL" && this.status_permission_outbox === "AL") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
         
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AD" && this.status_permission_outbox === "AL") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
           
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                {
                  icon: "delete_sweep",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AL" && this.status_permission_outbox === "AD") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
        
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                {
                  icon: "delete_sweep",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AL" && this.status_permission_outbox === "") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
       
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "AD" && this.status_permission_outbox === "") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
       
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                {
                  icon: "delete_sweep",
                  text: "toolbar.myinbox.Trashinbox",
                  link: "/trashinbox",
                  type: 3,
                },
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "" && this.status_permission_outbox === "AL") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
     
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else if (this.status_permission_inbox === "" && this.status_permission_outbox === "AD") {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
        
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
            
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                {
                  icon: "delete_sweep",
                  text: "toolbar.myoutbox.Trashoutbox",
                  link: "/trashoutbox",
                  type: 3,
                },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        } else {
          _items = [
             {
        active: true,
        icon: "mdi-domain",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/" + this.dataAccountActive["business_info"]["my_folder_id"],
        disable: false,
        key: this.feature_status.my_drive_bu,
      },
            {
              active: true,
              icon: "cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
            },
           
            {
              active: true,
              icon: "star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: false,
              key: this.feature_status.file_s,
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-down-outline",
              icon: "mdi-inbox-arrow-down-outline",
              "icon-alt": "mdi-inbox-arrow-down-outline",
              text: "toolbar.myinbox.Parentname",
              key: this.feature_status.inbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myinbox.Personalname",
                  link: "/myinbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myinbox.Businessname", ///////
                  link: "/myinbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myinbox.Trashinbox",
                //   link: "/trashinbox",
                //   type: 3
                // }
              ],
            },
            {
              active: true,
              prependicon: "mdi-inbox-arrow-up-outline",
              icon: "mdi-inbox-arrow-up-outline",
              "icon-alt": "mdi-inbox-arrow-up-outline",
              text: "toolbar.myoutbox.Parentname",
              key: this.feature_status.outbox,
              model: false,
              children: [
                {
                  icon: "face",
                  text: "toolbar.myoutbox.Personalname",
                  link: "/myoutbox-system",
                  type: 1,
                },
                {
                  icon: "business",
                  text: "toolbar.myoutbox.Businessname", //////
                  link: "/myoutbox-system",
                  type: 2,
                },
                // {
                //   icon: "delete_sweep",
                //   text: "toolbar.myoutbox.Trashoutbox",
                //   link: "/trashoutbox",
                //   type: 3,
                // },
              ],
            },
            {
              active: true,
              icon: "folder_shared",
              text: "toolbar.mysharefile",
              link: "/sharedwithme",
              disable: false,
              key: this.feature_status.shared,
            },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: false,
            },
            {
              active: true,
              icon: "delete_sweep",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
            },
          ];
        }
      } else {
        ////////////////////////////////////////////
        _items = [
          {
            active: true,
            icon: "cloud",
            text: "toolbar.mydrive",
            link: "/mydrive",
            disable: false,
            key: this.feature_status.my_drive,
          },
      //     {
      //   active: true,
      //   icon: "cloud",
      //   "icon-alt": "keyboard_arrow_down",
      //   text: "toolbar.mydrive_bu",
      //   link: "/directory/" + this.dataLoginDefault["my_folder_id"],
      //   disable: false,
      //   key: this.feature_status.my_drive_bu,
      // },
          {
            active: true,
            icon: "star",
            text: "toolbar.mystarfile",
            link: "/starred",
            disable: false,
            key: this.feature_status.file_s,
          },
          {
            active: true,
            prependicon: "mdi-inbox-arrow-down-outline",
            icon: "mdi-inbox-arrow-down-outline",
            "icon-alt": "mdi-inbox-arrow-down-outline",
            text: "toolbar.myinbox.Parentname",
            key: this.feature_status.inbox,
            model: false,
            children: [
              {
                icon: "assignment_ind",
                text: "toolbar.myinbox.Personalname",
                link: "/myinbox-system",
                type: 1,
              },
            ],
          },
          {
            active: true,
            prependicon: "mdi-inbox-arrow-up-outline",
            icon: "mdi-inbox-arrow-up-outline",
            "icon-alt": "mdi-inbox-arrow-up-outline",
            text: "toolbar.myoutbox.Parentname",
            key: this.feature_status.outbox,
            model: false,
            children: [
              {
                icon: "assignment_ind",
                text: "toolbar.myoutbox.Personalname",
                link: "/myoutbox-system",
                type: 1,
              },
            ],
          },
          {
            active: true,
            icon: "folder_shared",
            text: "toolbar.mysharefile",
            link: "/sharedwithme",
            key: this.feature_status.shared,
            disable: false,
          },
          {
            active: true,
            icon: "mdi-folder-clock",
            text: "toolbar.expiredfile",
            link: "/fileexpired",
            disable: false,
          },
          {
            active: true,
            icon: "delete_sweep",
            text: "toolbar.mytrash",
            link: "/trash",
            key: this.feature_status.trash,
            disable: false,
          },
        ];
      }
      let filterFeature = [];
      if (this.feature_status !== undefined) {
        filterFeature = _items.filter(this.checkfeature);
        // console.log("new1",filterFeature);
      }
      return filterFeature;
    },

    listitem() {
      let _items = [];
      console.log("admin1", this.role_level);
      if (this.dataAccountActive["type"] === "Business") {
        if (this.role_level === "True") {
          _items = [
            {
              active: true,
              text: "admintoolbar.admin",
              icon: "settings",
              link: "/system-admin-dashboard",
              disable: false,
              key: "admin",
            },
            // {
            //   active: true,
            //   icon: "mdi-bag-checked",
            //   text: "consentheader",
            //   link: "",
            //   disable: false,
            //   key: "consent",
            // },
            {
              active: true,
              icon: "mdi-account-circle",
              text: "Personal",
              link: "",
              disable: false,
              key: "profile",
            },
            // ใช้
            // {
            // active: true,
            // icon: "shopping_cart",
            // text: "ซื้อพื้นที่เก็บข้อมูล",
            // link: "",
            // disable: false,
            // key: "package",
            // },
            {
              active: true,
              icon: "power_settings_new",
              text: "logout",
              link: "",
              disable: false,
              key: "logout",
            },
          ];
        } else {
          _items = [
            // {
            //   active: true,
            //   icon: "mdi-bag-checked",
            //   text: "consentheader",
            //   link: "",
            //   disable: false,
            //   key: "consent",
            // },
            {
              active: true,
              icon: "mdi-account-circle",
              text: "Personal",
              link: "",
              disable: false,
              key: "profile",
            },
            //ใช้
            // {
            // active: true,
            // icon: "shopping_cart",
            // text: "ซื้อพื้นที่เก็บข้อมูล",
            // link: "",
            // disable: false,
            // key: "package",
            // },
            {
              active: true,
              icon: "power_settings_new",
              text: "logout",
              link: "",
              disable: false,
              key: "logout",
            },
          ];
        }
      } else {
        _items = [
          // {
          //     active: true,
          //     icon: "mdi-bag-checked",
          //     text: "consentheader",
          //     link: "",
          //     disable: false,
          //     key: "consent",
          //   },
          {
            active: true,
            icon: "mdi-account-circle",
            text: "Personal",
            link: "",
            disable: false,
            key: "profile",
          },
          // {
          //   active: true,
          //   icon: "shopping_cart",
          //   text: "ซื้อพื้นที่เก็บข้อมูล",
          //   link: "",
          //   disable: false,
          //   key: "package",
          // },
          {
            active: true,
            icon: "power_settings_new",
            text: "logout",
            link: "",
            disable: false,
            key: "logout",
          },
        ];
      }
      return _items;
    },
    listcctv() {
      let _items = [];
      console.log("cctv", this.dataAccountCCTV["status"]);
      if (this.dataAccountCCTV["status"] === true) {
        _items = [
          {
            active: true,
            text: "toolbar.cctv",
            icon: "mdi-cctv",
            link: "",
            disable: false,
            key: "cctv",
          },
        ];
      } else {
        _items = [];
      }
      return _items;
    },
    checkfeature(_item) {
      // console.log("1",_item.key);
      return _item.key !== "N";
    },
  },
  watch: {
    aspectSearch(val) {
      console.log("Watch aspect_data", val);
      this.setAspectData();
    },
    type_Fulltextsearch: function() {
      // if (val == "folder") {
      //   this.disabledatesearch = true;
      //   this.$emit("loadfile");
      // } else {
      this.disabledatesearch = false;
      // }
    },
    typeSearch: function(val) {
      if (val == "keywordsearch") {
        this.$emit("loadfile");
      }
    },
    permissionfolder: function(val) {
      console.log("val", val);
      console.log("this.permission_denied_account", this.permission_denied_account);
      if (val["upload"] == "False" || val === "01" || val === "02") {
        this.permission_denied_account = true;
      } else {
        this.permission_denied_account = false;
      }
    },
    checkroutername: function() {
      this.advancesearch = false;
    },
  },
  mounted() {
    this.version = process.env.VUE_APP_SERVICE_VERSION;
    this.setAspectData();
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
